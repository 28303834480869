@import "toastr";

$clientColor: #000000;
$body-color: #424345;

:root {
  --muted: #c9c9c9;
  --border-muted: #eee;
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap");

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
  cursor: auto !important;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
.btn {
  font-family: "bebas_neue", sans-serif;
  user-select: text;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

.sectionHeader {
  color: $clientColor;
  text-shadow: 1px 1px 4px#999;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .priceGreen {
    color: #028513;
    border-radius: 3px 8px 3px 3px;
    background-color: #e9edf0;
  }

  .priceBlack {
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 10%;
    color: white;
    min-width: 35px;
    font-size: 15px;
    text-align: center;
  }

  .priceInHeader {
    position: absolute;
    width: auto;
    right: 15px;
    color: #1f9500;
  }
  .ItemsSection {
    .priceInHeader {
      top: -10px;
    }
  }

  .activeImage {
    border: 2px dotted $clientColor;
    border-radius: 8px;
    padding: 1px;
  }

  .active,
  .activeText,
  .hover_effect:hover {
    background-color: rgba(74, 94, 143, 0.1);
    border-style: solid;
    border-color: $clientColor;
    color: $clientColor !important;
  }

  .checkMark {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    left: -10px;
    top: -10px;
    width: 22px;
    // mobile
    @media screen and (orientation: landscape) {
      left: -10px;
      top: -10px;
      width: 22px;
    }
  }

  .description {
    font-size: 14px;
    letter-spacing: 0.2px; // custom to thrill seekers
    color: $clientColor;
    // max-width: 70%;
  }
}

.displayNone {
  display: none;
}

.MuiLinearProgress-root {
  width: 100% !important;
  background: linear-gradient(90deg, $clientColor 0%, $body-color 53%, $clientColor 100%);
}

.MuiLinearProgress-barColorPrimary {
  background-color: #000000 !important;
}

#toast-container > .toast-info {
  color: black !important;
  background-image: url("../src/assets/info.svg") !important;
  background-repeat: no-repeat;
  background-size: 24px;
}
.toast-info {
  background-color: white !important;
}
.toast-warning {
  background-color: #ffc107 !important;
}
.toast-error {
  background-color: #c71608 !important;
}

// ***************
// fonts for text embroidery
// ***************

@font-face {
  font-family: "brush_script";
  src: url("/fonts/brush_script/BrushScriptStd.woff2") format("woff2"), url("/fonts/brush_script/BrushScriptStd.woff") format("woff"),
    url("/fonts/brush_script/BrushScriptStd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ancient_medium";
  src: url("/fonts/ancient_medium/AncientMedium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "bebas_neue";
  src: url("/fonts/bebas_neue/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "pp_handwriting";
  src: url("/fonts/pp_handwriting/hand.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ginchiest";
  src: url("/fonts/ginchiest/Ginchiest.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sign_painter";
  src: url("/fonts/sign_painter/SignPainter.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "impact";
  src: url("/fonts/impact/Impact.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
