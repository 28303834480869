.navigation {
  padding: 5px 5px !important;
  img {
    cursor: pointer;
    width: 62px;

    &.disable {
      opacity: 0.35;
      cursor: not-allowed;
    }
  }
  /*&.sticky {
    position: sticky;
    background-color: inherit;
    top: -1px;
    z-index: 3;
    margin-right: -12px; // break out of container padding
    margin-left: -12px;

    &.isSticking {
      box-shadow: 0px 3px 8px 1px #555;
    }
  }*/
  @media screen and (orientation: portrait) {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 1;
    margin-bottom: 0px !important;
    width: 100%;
    margin-bottom: 0;
    padding: 10px 0px !important;
    box-shadow: 0 1px 10px 3px #8c8c8c;
    z-index: 10;
    transform: translateZ(0);
    perspective: 1000;
  }
}
