$clientColor: #000000;
$md: 768px !default;

.modal-dialog {
  .modal-header {
    background-color: $clientColor;
    color: white;
  }

  .action-buttons {
    margin-bottom: 100px;
    @media (min-width: $md) {
      margin-bottom: initial;
    }
  }

  p,
  h3,
  h4 {
    color: $clientColor;
    text-align: center;
  }
  .brandImage {
    width: 30%;
  }

  .warningIcon {
    width: 20px;
    height: 20px;
  }

  .final-items-review {
    font-family: "Arial";
    font-size: 14px;
  }

  input {
    &.form-check-input {
      height: 15px;
      width: 15px;

      &.form-check-input:checked {
        background-color: $clientColor;
        border-color: $clientColor;
      }
    }

    &.form-number-input {
      border-radius: 6px;
      border: 1px solid #ced4da;
      color: $clientColor;
      outline: none;
      padding: 0.375rem 0.75rem;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    }
  }

  textarea {
    border-radius: 6px;
    color: $clientColor;
    outline: none;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
  }

  button {
    background-color: $clientColor;
    color: white;
    box-shadow: 0px 1px 2px #888888;
    border: none;
    border-radius: 4px;
    &:hover {
      background-color: $clientColor;
    }
  }

  .submitBtn:disabled {
    background-color: #b5b4b4;
    &:hover {
      background-color: #b5b4b4;
    }
  }
}
