#BuildSection {
  position: fixed;
  z-index: 1000;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #f7f7f7;
  left: 0;

  .headers {
    color: black;
  }

  .brandmark-padding {
    padding-top: 300px;
    width: 100%;
    position: relative;
  }

  .tastetest-brandmark {
    border-top: 1px solid lightgrey;
    p {
      font-size: 13px;
    }
    img {
      width: 30px;
    }
  }
  @media screen and (orientation: landscape) {
    top: 0;
    height: 100%;
    // this needs to match width and margin-left of .shared-scene-sizing in BuilderScreen styles
    width: 450px;
  }

  @media screen and (orientation: portrait) {
    bottom: 0px;
    padding-bottom: 55px;
    height: 55%;
    width: 100%;
  }
}
