@import url("https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap");
$clientColor: #000000;

.BuilderUI {
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-out;

  canvas {
    &:focus {
      outline: none;
    }
  }
}

.containerWidgets {
  position: relative;
  width: 100%;
  z-index: 2001;
  pointer-events: none;
}
.innerWrapper {
  display: flex;
  position: absolute;
  pointer-events: all;
}
.flexMobile {
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-between;
  bottom: 0;
}
.flexNormal {
  flex-direction: row;
  top: 0;
}
