.rectangleBtn {
  position: relative;
  z-index: 1;
  border-radius: 4px 4px 4px 4px;
  border: none;
  user-select: none;
  font-size: 20px;
  color: black;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  &.disabled {
    opacity: 0.5;
  }

  img {
    width: 25px;
  }

  svg {
    width: 25px;
    height: 21px;
  }

  @media (max-width: 767px) {
    svg {
      height: 25px;
    }
  }
}
