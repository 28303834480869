$clientColor: #4a5e8f;

.accordion_item {
  padding: 13px 10px;
  border-style: solid;
  width: 100%;
  border-color: #f5f6f4;
  cursor: pointer;
  background-color: #f5f6f4;
  box-shadow: 0 1px 10px 3px #8c8c8c;
  border-radius: 8px;

  .previewThumbnail {
    width: 30px;
    border-radius: 100px;
  }

  .overlayTexture {
    z-index: 1;
    position: absolute;
  }

  .expandIcon {
    width: 25px;
  }

  &.parent {
    width: 100%;
  }
  &.nested {
    width: 90%;
    box-shadow: initial;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    padding: 10px 10px;
  }
}

.eye-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  cursor: pointer;
  fill: #222222;
  transition: all 0.1s linear;
  &:hover {
    fill: #c71608;
  }
}

.new-label {
  color: #ff8a39;
  margin-right: 2px;
  img {
    height: 25px;
  }
}
