.Stepper {
  &.MuiStepper-root {
    background-color: inherit;
  }
  // removes number in center of circle
  .MuiStepIcon-text {
    display: none;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 7px;
  }

  .MuiTypography-body2 {
    font-family: "bebas_neue", sans-serif;
  }
}
