.images-container {
  hr {
    color: black;
    background-color: black;
    height: 1px;
    width: 30px;
    opacity: 1;
    box-shadow: none;
    margin-bottom: 8px !important;
  }
  .image-container {
    transition: all 0.2s linear;
    position: relative;
    gap: 4px;
    &:hover {
      box-shadow: 0 1px 10px 3px #8c8c8c;
      background-color: #ffffff;
      border-radius: 4px;
    }
    img {
      object-fit: contain;
      cursor: pointer;
      width: 100%;
    }
  }
  .active-item {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 10px 3px #8c8c8c;
  }

  .checkmark {
    position: absolute;
    border-radius: 50%;
    left: -10px;
    top: -10px;
    width: 22px;
    height: 22px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
